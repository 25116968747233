/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://6b6b7zkvzzeetnmahfydzwwiyi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-vrpmjyzj6jhgne543o7fvnedui",
    "aws_cloud_logic_custom": [
        {
            "name": "bordercrossRest",
            "endpoint": "https://2gq6xlzn74.execute-api.eu-west-1.amazonaws.com/stage",
            "region": "eu-west-1"
        },
        {
            "name": "handleStripeWebhooks",
            "endpoint": "https://4mv5qzaus9.execute-api.eu-west-1.amazonaws.com/stage",
            "region": "eu-west-1"
        },
        {
            "name": "orgData",
            "endpoint": "https://wnhuv1ek99.execute-api.eu-west-1.amazonaws.com/stage",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:1de85cd7-72b7-4773-8343-e524f01212c7",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_VD43jmhHl",
    "aws_user_pools_web_client_id": "4a0jeteijp17pfdqehl1e82itu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bordercross-health-amplify-storage231741-stage",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
